.wrapper {
	background-color: #fefefe;
	margin: auto;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 710px) {
	.wrapper {
		box-shadow: none;
		padding-top: 20px;
	}
}

@media (max-width: 500px) {
	.wrapper {
		width: 250px;
	}
}
