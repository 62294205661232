.wrapper {
	display: flex;
	flex-direction: column;
	padding: 98px 66px 42px;
	max-width: 1200px;
}

.content {
	max-width: 400px;
	width: 100%;
}

.skeletonItem {
	margin-top: 35px;
	height: 60px;
	max-width: 450px;
}
