@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.pagination {
		@apply w-12 h-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full;
	}

	.customLink > a {
		@apply text-blue-600 hover:text-blue-800;
	}
}
