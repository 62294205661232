.body {
	font-family: 'Ubuntu', sans-serif;
	font-style: normal;
	display: flex;
	width: 100%;
	height: 100%;
	background-image: url(../../../img/draft-background.jpg);
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	min-height: calc(100vh - 278.5px - 274px);
}

.wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 70%,
		rgba(255, 255, 255, 0.9472163865546218) 80%,
		rgba(255, 255, 255, 0.9220063025210083) 90%,
		rgba(255, 255, 255, 0.7259278711484594) 100%
	);
	justify-content: center;
	min-height: calc(100vh - 278.5px - 274px);
}

.main {
	display: flex;
	margin: 0 auto;
}

.section {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 590px;

	padding: 110px 40px 200px;
	box-sizing: content-box;
}

.section h1 {
	font-weight: 700;
	font-size: 36px;
	line-height: 128.69%;
	letter-spacing: 0.1em;
	color: #333333;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 75px;
}

.section p {
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	margin-right: 10px;

	color: #bfbfbf;
}

.loginInput {
	padding: 10px 20px;
	width: 100%;
	outline: none;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	/* margin-bottom: 26px; */
	height: 70px;
	color: black;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
}

.btn {
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
	text-align: center;

	color: #ffffff;
	background: #00aeae;
	border-radius: 5px;
	max-width: 340px;
	width: 100%;
	height: 70px;
	margin-top: 50px;
	margin-bottom: 25px;
}

.registrationBlock {
	display: flex;
	justify-content: center;
}

.registrationLink {
	display: inline-block;
	color: rgb(124 124 124);
	text-decoration: underline;
}

.registrationLink:hover {
	color: rgb(105, 105, 105);
}

@media (max-width: 600px) {
	.form {
		width: auto;
	}
}
