.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 16px 0;
	gap: 10px;
}

.wrapperItem {
	display: flex;
}
