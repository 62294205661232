.active-link {
    color: lightgoldenrodyellow;
}

.wrapper {
    /* min-height: 100vh; */
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* align-items: center; */
    /* width: 100%; */
}

.main__content {
    padding: 0 26px;
}

.footer {
    padding: 30px;
}
