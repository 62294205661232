.wrapper {
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	width: 100%;
}

.lds {
	display: inline-block;
	position: sticky;
	width: 100px;
	height: 100px;
	top: 50%;
	left: calc(50% - 50px);
	z-index: 1301;
}
.lds div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #00aeae20;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}
.lds div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}
.lds div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}
@keyframes lds-facebook {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}
