.newsHeader {
	padding: 0 20px 20px;
}

.newsContent {
	ul {
		list-style: disc;
		padding-left: 20px;
	}

	ol {
		list-style: decimal;
		padding-left: 20px;
	}

	h2,
	h3,
	h4 {
		font-weight: 600;
		margin-bottom: 20px;
	}

	h2 {
		font-size: 1.4em;
	}

	h2 {
		font-size: 1.3em;
	}

	h4 {
		font-size: 1.2em;
	}

	p {
		margin-bottom: 10px;
	}

	a {
		color: rgb(0 174 174);
		text-decoration: underline;
	}
}
