.notifications {
    position: relative;
}

.notificationsIcon {
    color: white;
    cursor: pointer;
    position: relative;
    margin-right: 1rem;
}

.notificationsBox {
    display: flex;
    flex-direction: column;
    /* max-height: 50vh; */
    width: 300px;
    border-radius: 8px;
    position: absolute;
    top: 2rem;
    right: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: #00aeae;
    color: white;
    z-index: 5;
}
.notificationsBoxMobile {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    width: 300px;
    border-radius: 8px;
    position: absolute;
    top: 2rem;
    right: -130px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background: #00aeae;
    color: white;
    z-index: 5;
}

.notificationsHeader {
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notificationsBox h3 {
    margin-top: 8px;
    font-weight: 700;
    font-size: 20px;
}

.notificationsBoxMobile h3 {
    margin-top: 25px;
    font-weight: 700;
    font-size: 20px;
}

.markAsRead {
    cursor: pointer;
    font-weight: 700;
    opacity: 0.8;
}

.notification {
    font-size: 14px;
    margin: 1rem 0;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid rgb(207, 207, 207);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 0.5rem 1rem;
}

.notRead {
    background: #ffffff;
    color: #4b525c;
}

.notificationTime {
    margin-top: 0.2rem;
    font-size: 12px;
    color: #747474;
}

.notificationCount {
    display: flex;
    background: #ff0000;
    height: 25px;
    width: 25px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -15px;
}

.thisUserNotifications {
    display: flex;
    background: #00bd9b;
    height: 20px;
    width: 20px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.notificationList {
    display: flex;
    flex-direction: column;
    max-height: 270px;
    overflow: auto;
}

.pulsate::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: pulse 1s ease infinite;
    border-radius: 50%;
    /* border: 4px double lighten(red, 20%); */
    border: 2px #ff0000 solid;
    /* border: 2px #ff3737 solid; */
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    60% {
        transform: scale(1.5);
        opacity: 0.4;
    }
    100% {
        transform: scale(1.7);
        opacity: 0;
    }
}
