.wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	background-image: url('../../img/draft-background.jpg');
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	min-height: calc(100vh - 278.5px - 274px);
}

.wrapper2 {
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 278.5px - 274px);
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 70%,
		rgba(255, 255, 255, 0.9472163865546218) 80%,
		rgba(255, 255, 255, 0.9220063025210083) 90%,
		rgba(255, 255, 255, 0.7259278711484594) 100%
	);
}

.fileUpload {
	display: flex;
	flex-direction: column;

	.file-input {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.file-label {
		padding: 10px;
		background-color: #00aeae;
		color: white;
		margin: 0 auto;
		border-radius: 5px;
		cursor: pointer;
		margin-top: 10px;
	}

	button {
		padding: 5px 10px;
		border-radius: 5px;
		color: white;
		background-color: #4b525c;
		margin-top: 15px;
	}
}

.content {
	display: flex;
	color: #333333;
	max-width: 1474px;
	width: 100%;
	margin: 100px auto;
	justify-content: space-between;
	padding: 0 17px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;

	@media screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: center;
	}

	.btnBack {
		@media screen and (max-width: 480px) {
			display: none;
		}
	}

	.image {
		width: 900px;
		height: 700px;

		@media screen and (max-width: 1400px) {
			width: 700px;
		}

		@media screen and (max-width: 1200px) {
			height: auto;
		}

		@media screen and (max-width: 770px) {
			width: 500px;
			margin-bottom: 30px;
		}

		@media screen and (max-width: 570px) {
			width: 400px;
		}

		@media screen and (max-width: 430px) {
			width: 300px;
		}
	}

	.slider {
		padding: 0;
	}
	.backBtn {
		background: #00aeae;
		border-radius: 5px;
		max-width: 220px;
		padding: 10px 25px;
		margin-bottom: 20px;
		color: #ffffff;

		@media screen and (max-width: 480px) {
			font-size: 20px;
		}
	}

	.ampBtn {
		background: #00aeae;
		border-radius: 5px;
		max-width: 220px;
		padding: 10px 25px;
		margin-top: 50px;
		color: #ffffff;
		margin-left: 30px;

		@media screen and (max-width: 480px) {
			margin: 50px auto 0;
			font-size: 20px;
		}
	}

	.btnDisabled {
		background: #ccc;
		border-radius: 5px;
		max-width: 220px;
		padding: 10px 25px;
		margin-top: 50px;
		color: #ffffff;
		margin-left: 30px;

		@media screen and (max-width: 480px) {
			margin: 50px auto 0;
			font-size: 20px;
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	width: 500px;
	margin-left: 10px;

	@media screen and (max-width: 570px) {
		width: 100%;
	}

	.infoNumber {
		display: flex;
		padding: 11px 30px;
		background: #333333;
		border-radius: 5px;
		width: 140px;
		font-weight: 500;
		font-size: 36px;
		color: #ffffff;

		@media screen and (max-width: 1200px) {
			margin-top: 10px;
		}

		@media screen and (max-width: 480px) {
			font-size: 24px;
			width: 102px;
			padding: 6px 15px;
		}
	}

	.infoTitle {
		margin-top: 47px;
		font-weight: 500;
		font-size: 24px;
		color: #00aeae;

		@media screen and (max-width: 480px) {
			font-size: 20px;
			margin-top: 27px;
		}
	}

	.infoDetails {
		margin-top: 16px;
		font-size: 18px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}
	}

	.infoCategory {
		margin-top: 16px;
		font-size: 18px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}
	}

	.infoKl {
		margin-top: 16px;
		font-size: 18px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}
	}

	.infoMany {
		font-size: 18px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}
	}

	.infoCities {
		font-size: 18px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}
	}

	.infoDoc {
		display: flex;
		flex-direction: column;
		margin-top: 25px;
		font-weight: 500;
		font-size: 18px;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}
	}

	.docItem {
		display: flex;
		align-items: center;
		margin-top: 20px;
		font-weight: 400;
		font-size: 18px;
		text-decoration-line: underline;
		color: #7c7c7c;

		@media screen and (max-width: 480px) {
			font-size: 16px;
		}

		img {
			margin-right: 10px;

			@media screen and (max-width: 480px) {
				height: 30px;
			}
		}
	}
}

.requestActual {
	width: 75%;
	margin-top: 20px;
	/* font-size: 0.9em;
	line-height: 1.2em; */
	color: #d18b4d;
	display: flex;
	align-items: center;

	@media screen and (max-width: 480px) {
		font-size: 1em;
		flex-direction: column;
	}
}

.actBtn {
	background: #00aeae;
	border-radius: 5px;
	max-width: 220px;
	padding: 10px 10px;
	color: #ffffff;
	font-size: 1em;
	line-height: 16px;
	margin-left: 20px;
	height: fit-content;
	display: flex;
	align-items: center;

	@media screen and (max-width: 480px) {
		font-size: 20px;
		line-height: 24px;
		margin-top: 10px;
	}
}
