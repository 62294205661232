.main {
	display: flex;
	color: black;
	/* font-family: 'Roboto', sans-serif; */
	/* font-style: normal; */
	/* font-weight: 400; */
}

.main h2 {
	font-weight: 500;
	font-size: 24px;
	color: #333333;
}

@media (max-width: 1200px) {
	.main {
		flex-direction: column;
	}
}

.contextWrapper {
	display: flex;
	flex-direction: column;
	padding: 98px 66px 72px;
	max-width: 1200px;
	box-sizing: content-box;
}

@media (max-width: 768px) {
	.contextWrapper {
		padding: 68px 46px 42px;
	}
}

@media (max-width: 480px) {
	.contextWrapper {
		padding: 48px 16px 42px;
	}
}

.actionMenu {
	display: flex;
	margin-top: 35px;
	font-size: 20px;
	text-decoration-line: underline;
	color: #00aeae;
}
.action {
	display: flex;
	width: 320px;
	justify-content: flex-end;
	margin-top: 95px;
	font-size: 18px;
	text-decoration-line: underline;
	color: #bfbfbf;
}

.inputCities {
	padding: 20px 30px;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	outline: none;
	width: 386px;
	color: black;
}

@media (max-width: 1750px) {
	.inputCities {
		width: 100%;
		margin-bottom: 25px;
	}
}

@media (max-width: 480px) {
	.inputCities {
		font-size: 16px;
		padding: 10px 20px;
		border: 1px solid #bfbfbf;
		margin-top: 15px;
	}
}

.citiesDiv {
	display: flex;
	max-width: 386px;
	position: relative;
}

.form {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}

@media (max-width: 480px) {
	.form {
		margin-top: 0;
	}
}
.upload {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 40px;
}

.fileBlock > label {
	font-size: 2rem;
	color: #00aeae;
	font-weight: 600;
}

/* .top label {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 20%;
	font-size: 16px;
	color: #bfbfbf;
	cursor: pointer;
} */

/* .top input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
} */

.top {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	width: 100%;
	position: relative;
	margin-bottom: 25px;
}

@media (max-width: 1750px) {
	.top {
		display: block;
		flex-direction: column;
	}
}

.inputWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
}

.textAreaWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
}

.err {
	position: absolute;
	color: red;
	left: 15px;
	/* margin-bottom: -15px; */
	/* margin-top: 10px; */
}

.longInput {
	padding: 20px 30px;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	outline: none;
	width: 100%;
	max-width: 590px;
	color: black;
	font-size: 18px;
}

.shortInput {
	padding: 20px 30px;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	outline: none;
	max-width: 590px;
	color: black;
	max-width: 386px;
	width: 100%;
}

.select {
	padding: 20px 30px;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	outline: none;
	width: 100%;
	max-width: 590px;
	color: black;
	background-color: white;
}

.textArea {
	padding: 25px 21px;
	font-size: 18px;
	color: black;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	width: 100%;
	height: 200px;

	resize: none;
	outline: none;
}

.acceptBtn {
	display: flex;
	width: 340px;
	height: 67px;
	align-items: center;
	justify-content: center;
	background: #00aeae;
	border-radius: 5px;
	margin: 0 auto;
	margin-top: 89px;
	font-size: 24px;
	color: #ffffff;
}

.toms {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 20px;
}

.tom {
	display: flex;
	border: 2px solid #bfbfbf;
	padding: 10px 20px;
	border-radius: 5px;
	align-items: center;
	font-size: 18px;
	color: black;
	height: 50px;
}

.close {
	color: black;
	float: right;
	font-size: 40px;
	margin-left: 20px;
}

.close:hover,
.close:focus {
	color: #00aeae;
	text-decoration: none;
	cursor: pointer;
}

@media (max-width: 1750px) {
	.top {
		margin-bottom: 0;
	}
	.longInput {
		max-width: none;
		margin-bottom: 25px;
	}
	.shortInput {
		max-width: none;
		margin-bottom: 25px;
	}
	.select {
		max-width: none;
		margin-bottom: 25px;
	}
	.citiesDiv {
		max-width: none;
	}
}

@media (max-width: 480px) {
	.longInput {
		font-size: 16px;
		padding: 10px 20px;
		border: 1px solid #bfbfbf;
		margin-bottom: 15px;
	}
	.shortInput {
		font-size: 16px;
		padding: 10px 20px;
		border: 1px solid #bfbfbf;
		margin-bottom: 15px;
	}
	.select {
		font-size: 16px;
		padding: 10px 20px;
		border: 1px solid #bfbfbf;
		margin-bottom: 15px;
	}

	.textArea {
		font-size: 16px;
		padding: 10px 20px;
		border: 1px solid #bfbfbf;
	}
	.acceptBtn {
		width: 240px;
		font-size: 22px;
		margin-top: 50px;
	}
}
