.wrapper1 {
	display: flex;
	width: 100%;
	height: 100%;
	background-image: url('../../img/draft-background.jpg');
	background-size: contain;
	min-height: calc(100vh - 278.5px - 274px);
}

.wrapper2 {
	width: 100%;
	height: 100%;
	background: radial-gradient(
		circle,
		rgba(255, 255, 255, 1) 70%,
		rgba(255, 255, 255, 0.9472163865546218) 80%,
		rgba(255, 255, 255, 0.9220063025210083) 90%,
		rgba(255, 255, 255, 0.7259278711484594) 100%
	);
	min-height: calc(100vh - 278.5px - 274px);

	@media screen and (max-width: 1200px) {
		background: radial-gradient(
			circle,
			rgba(255, 255, 255, 1) 91%,
			rgba(255, 255, 255, 0.8155637254901961) 100%,
			rgba(255, 255, 255, 0.8) 100%
		);
	}
}

.finded {
	display: flex;
	margin-top: 40px;
	color: black;
	font-family: 'Ubuntu', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #808080;

	span {
		color: #00aeae;
	}
}

.body {
	display: flex;
	min-height: 59vh;
	flex-direction: column;
}
.findModal {
	display: flex; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 50px; /* Location of the box */
	padding-bottom: 50px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: #000000ae; /* Black w/ opacity */
}

.form {
	display: flex;

	width: 100%;
	max-width: 1220px;
	margin: 0 auto 70px;
	color: black;
	flex-direction: column;
	position: relative;

	@media screen and (max-width: 480px) {
		font-size: 16px;
		padding: 10px;
	}

	:global .span-title {
		font-size: 22px;
		text-align: center;
		margin-bottom: 15px;
	}

	input {
		width: 100%;
		padding: 20px 14px 20px 33px;

		margin-right: 20px;
		border: 1px solid #e0e0e0;
		font-family: 'Ubuntu', sans-serif;
		font-style: italic;
		font-weight: 400;
		font-size: 18px;
		line-height: 21px;
		outline: none;

		color: black;

		@media screen and (max-width: 480px) {
			font-size: 16px;
			padding: 10px;
		}
	}
}
.index {
	display: flex;
	height: 30px;
	width: 30px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: tomato;
	position: absolute;
	top: 33px;
	right: 50px;
	padding-left: 1px;
	color: white;

	@media screen and (max-width: 830px) {
		top: auto;
		bottom: 32px;
		right: 5px;
	}

	@media screen and (max-width: 480px) {
		top: auto;
		bottom: 70px;
		right: 15px;
	}
}

.search {
	display: flex;
	align-items: center;

	@media screen and (max-width: 830px) {
		flex-direction: column;
		padding: 0 20px;
	}

	button {
		font-family: 'Ubuntu', sans-serif;
		font-size: 18px;
		background: #00aeae;
		cursor: pointer;
		color: white;
		padding: 4px;

		@media screen and (max-width: 830px) {
			padding: 10px 15px;
			margin-left: auto;
			margin-top: 30px;
		}
	}
}

.resetFilterIcon {
	fill: #00aeae;
	width: 100px;
	height: 40px;
	cursor: pointer;
}

.checkboxsWrapper {
	height: 50vh;
	width: auto;
	overflow: auto;
	overflow-x: auto;
	padding-right: 10px;
}

.modalContent {
	position: relative;
	background-color: #fefefe;
	margin: auto;
	padding: 55px 79px;
	width: 85%;
	height: auto;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.4s;
	z-index: 51;

	@media screen and (max-width: 1024px) {
		padding: 35px 59px;
	}

	@media screen and (max-width: 768px) {
		padding: 25px 20px;
	}

	@-webkit-keyframes animatetop {
		from {
			left: -300px;
			opacity: 0;
		}
		to {
			left: 0;
			opacity: 1;
		}
	}

	@keyframes animatetop {
		from {
			left: -300px;
			opacity: 0;
		}
		to {
			left: 0;
			opacity: 1;
		}
	}

	.close {
		color: black;
		float: right;
		font-size: 40px;
		font-weight: bold;
		margin-right: 40px;
		@media screen and (max-width: 768px) {
			margin-right: -4px;
			margin-top: -10px;
		}
	}

	.close:hover,
	.close:focus {
		color: #00aeae;
		text-decoration: none;
		cursor: pointer;
	}

	button {
		display: flex;
		padding: 15px 35px;
		margin: 0 auto;
		background: #00aeae;
		border-radius: 5px;
		margin-top: 25px;
		cursor: pointer;

		font-family: 'Ubuntu', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		color: #ffffff;
	}
}

.modalBody {
	margin-top: 30px;
	column-count: 3;
	text-align: left;
	color: black;
	/* z-index: 50; */

	.width100 {
		width: 100%;
	}

	@media screen and (max-width: 1024px) {
		column-count: 2;
	}

	@media screen and (max-width: 768px) {
		column-count: 1;
	}
}

.btnWrapper {
	display: flex;

	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}
}
