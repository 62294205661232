.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f6f6;
    height: 440px;
    max-width: 368px;
    -webkit-box-shadow: 0px 0px 7px 4px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 7px 4px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 7px 4px rgba(34, 60, 80, 0.2);
    position: relative;
    border-radius: 4px;

    /* font-family: 'Roboto', sans-serif; */
    /* font-weight: 400; */
    /* font-size: 18px; */
    color: #333333;
}

.number {
    display: flex;
    width: 86px;
    height: 36px;
    background: #333333;
    border-radius: 5px;
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 43px);
    justify-content: center;
    /* font-family: 'Roboto'; */
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    align-items: center;
    color: #ffffff;
}

.line {
    text-decoration: underline;
}

@media (max-width: 639px) {
    .wrapper {
        max-width: 380px;
    }
}

@media (max-width: 711px) {
    .wrapper {
        margin-bottom: 12px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.3);
        -moz-box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.3);
        box-shadow: 0px 0px 5px 0px rgba(34, 60, 80, 0.3);
    }
}

.cardImg {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 50%;
}

.draft {
    /* height: 100%; */
    border-radius: 8px;
    object-fit: cover;
    /* object-fit: contain; */
    padding: 5px;
    width: 100%;
    /* max-width: 400px; */
    height: 100%;
}

/* @media screen and (max-width: 480px) {
	.wrapper {
		width: 100%;
	}
} */

.cardStar {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    height: 30px;
}

.link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.name {
    display: block;
    font-weight: 500;
    text-align: center;
    margin-top: 38px;
    max-height: 54px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* количество строк */
    -webkit-box-orient: vertical;
    /* display: -webkit-box; */
}

.name:first-letter {
    text-transform: uppercase;
}

.details {
    display: flex;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    margin-top: 15px;
    max-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* количество строк */
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.more {
    display: flex;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #bfbfbf;
    margin-top: 22px;
}
