.body {
	display: flex;
	padding: 0 26px;
	gap: 20px;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 20px;

	@media screen and (max-width: 710px) {
		display: none;
	}
}
.adv {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	max-width: 420px;
	min-width: 200px;
	margin-top: 15px;
	position: relative;
	cursor: pointer;

	img {
		min-height: 140px;
		max-height: 140px;
		width: 100%;
		object-fit: cover;
		object-position: 0 50%;
	}
}

.overlayText {
	background-color: #4b525c;
	color: white;
	height: 100%;
	padding: 5px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
