.body {
    display: flex;
    color: #333333;

    /* font-family: 'Roboto', sans-serif; */
    /* font-style: normal; */
    /* font-weight: 400; */
}

.contextWrapper {
    display: flex;
    flex-direction: column;
    padding: 98px 0 42px 66px;
    max-width: 1200px;
    width: 100%;
}

.gridWrapper {
    width: 100%;
}

.contextWrapper h2 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
}

@media (max-width: 1024px) {
    .gridWrapper {
        width: 95%;
        text-align: -webkit-center;
    }
    .contextWrapper {
        align-items: center;
        padding: 0;
        padding-top: 10px;
    }
}
