.body {
	display: flex;
	color: black;
	/* font-family: 'Roboto', sans-serif; */
	/* font-style: normal; */
	/* font-weight: 400; */
}

.user {
	flex: 4;
	/* padding: 20px; */
	padding: 69px 37px 52px;
}

.userTitleContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.newUserItem {
	width: 400px;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-right: 20px;
}

.userAddButton {
	width: 80px;
	border: none;
	padding: 5px;
	background-color: teal;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 16px;
}

.userContainer {
	display: flex;
	margin-top: 20px;
}

.userShow {
	flex: 1;
	padding: 20px;
	-webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.userUpdate {
	flex: 2;
	padding: 20px;
	/* -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75); */
	/* box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75); */
	margin-left: 20px;
}

.userShowTop {
	display: flex;
	align-items: center;
}

.userShowImg {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.userShowTopTitle {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
}

.userShowUsername {
	font-weight: 600;
}

.userShowUserTitle {
	font-weight: 300;
}

.userShowBottom {
	margin-top: 20px;
}

.userShowTitle {
	font-size: 18px;
	font-weight: 600;
	color: rgb(175, 170, 170);
}

.userShowTitlePassword {
	font-size: 18px;
	font-weight: 800;
	color: rgb(197, 12, 12);
}

.userShowInfo {
	display: flex;
	align-items: center;
	margin: 20px 0px;
	color: #444;
}

.userShowIcon {
	font-size: 16px !important;
}

.userShowInfoTitle {
	margin-left: 10px;
}

.userUpdateTitle {
	font-size: 24px;
	font-weight: 600;
}

.newUserForm {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	/* max-width: 650px; */
}

.newUserFormItem {
	display: flex;
	flex-wrap: wrap;
}

.userUpdateItem {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.userUpdateItem > label {
	margin-bottom: 5px;
	font-size: 14px;
}

.newUserItem > input {
	font-size: 18px;
	height: 20px;
	padding: 10px;
	border: 1px solid gray;
	border-radius: 5px;
}

.userUpdateInput {
	border: none;
	width: 250px;
	height: 30px;
	border-bottom: 1px solid gray;
}

.userUpdateRight {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.userUpdateUpload {
	display: flex;
	align-items: center;
}

.userUpdateImg {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	object-fit: cover;
	margin-right: 20px;
}

.userUpdateIcon {
	cursor: pointer;
}

/* .userUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 10px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
} */

.UserSelect {
	height: 40px;
	border-radius: 5px;
}

.userUpdateButton {
	width: 200px;
	border: none;
	background-color: #00aeae;
	color: white;
	padding: 17px 10px;
	font-weight: 600;
	border-radius: 10px;
	margin-top: 30px;
	cursor: pointer;
}

.oweners {
	display: flex;
	flex-direction: column;
	margin-top: 50px;
	margin-left: 20px;
}

.search {
	margin-top: 20px;
}

.ownWrapper {
	display: block;
	max-height: 500px;
	overflow: scroll;
}

.ownList {
	column-width: 200px;
	column-count: 2;
	margin-top: 20px;
	max-width: 100%;
	min-height: 500px;
}

.ownListInput {
	margin-top: 10px;
}

.ownListLable {
	margin-top: 10px;
	font-size: 16px;
}
.fileBlock {
	width: 80%;
	padding: 10px 0;
}

.fileBlock > label {
	font-size: 2rem;
	color: #00aeae;
	font-weight: 600;
}

.plantInfoWrapper {
	width: 80%;
}

.plantInfo {
	width: 100%;
	margin-top: 10px;
}

.plantInfo > textarea {
	padding: 20px;
	font-size: 16px;
	color: black;
	border: 2px solid #bfbfbf;
	border-radius: 5px;
	width: 100%;
	height: 200px;
	resize: none;
	outline: none;
}
