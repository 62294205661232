.main {
    display: flex;
    width: 100%;
    height: 100%;
    /* background-image: url(background); */
    background-size: 100%;
    min-height: calc(100vh - 278.5px - 274px);
}

.wrapper {
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 72%,
        rgba(255, 255, 255, 0.8155637254901961) 100%,
        rgba(255, 255, 255, 0.8) 100%
    );
    min-height: calc(100vh - 278.5px - 274px);
}

@media (max-width: 1200px) {
    .wrapper {
        background: radial-gradient(
            circle,
            rgba(255, 255, 255, 1) 91%,
            rgba(255, 255, 255, 0.8155637254901961) 100%,
            rgba(255, 255, 255, 0.8) 100%
        );
    }
}

.body {
    margin: 0 auto;
    padding: 10px 26px;
    min-height: 1800px;
    width: 100%;
    max-width: 1492px;
    /* font-family: 'Roboto', sans-serif; */
    /* font-weight: 400; */
    /* color: #333333; */
}

@media (max-width: 480px) {
    .body {
        padding: 0 16px;
    }
}

.filterBurger {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 22px;
}

.filterImg {
    width: 30px;
    margin-left: auto;
}

.categoryWrapper {
    display: none;
    justify-content: center;
    margin-top: 24px;
}

.categoryWrapper .category {
    width: 100%;
}

@media (max-width: 710px) {
    .categoryWrapper {
        display: flex;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: black;
    margin: 100px 0 400px;
    width: 1200px;
}

.findModal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    padding-bottom: 50px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: #000000ae; /* Black w/ opacity */
}

.box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    box-sizing: content-box;
    color: white;
}

.content {
    display: flex;
    justify-content: center;
    max-width: 1440px;
    width: 100%;
}

.sideBarPlantMobile {
    margin-bottom: 10px;
}

/* .drawerContent {
	height: 416px;
} */

.categoryWrapperLeft {
    width: 300px;
    height: 100%;
    margin-right: 20px;
}

@media (max-width: 710px) {
    .content {
        flex-direction: column;
    }
}

/* .notFound {
  margin-top: 30px;
  font-size: 18px;
  color: #808080;
  text-align: center;
} */

.emptyBlock {
    height: 525px;
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 18px;
}

@media (max-width: 639px) {
    .emptyBlock {
        height: 225px;
    }
}

.plantListWrapper {
    width: 80%;
}

@media (max-width: 500px) {
    .plantListWrapper {
        width: auto;
    }
}
