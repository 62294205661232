.navigation a.active {
	background-color: #f6f6f6;
	color: #00aeae;
	border: 1px solid #00aeae;
}

.navigation {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.infoButton {
	display: flex;
	max-width: 340px;
	width: 100%;
	height: 67px;
	align-items: center;
	justify-content: center;
	background: #00aeae;
	border-radius: 5px;
	margin-top: 20px;
	font-size: 24px;
	text-align: center;
	color: #ffffff;
}

@media (max-width: 500px) {
	.infoButton {
		max-width: 280px;
	}
}
