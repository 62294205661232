.wrapper {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 16px;
	/* width: 80%; */
}

.cardContent {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 100%;
}

@media (max-width: 1024px) {
	.wrapper {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

@media (max-width: 768px) {
	.wrapper {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

@media (max-width: 480px) {
	.wrapper {
		width: auto;
	}
}
