.notActualNotice {
	font-size: 0.8em;
	color: #e56877;
	display: block;
	cursor: pointer;
	display: flex;
	align-items: center;

	.infoIcon {
		margin-right: 10px;
		fill: currentColor;
	}
}

.notActualIcon {
	fill: #c8d4d8;
	width: 25px;
	height: 25px;
}

.notActualStatus {
	color: #e56877;
	cursor: pointer;
	text-decoration: underline;
}

.orderStatus {
	margin-top: auto;
	padding-top: 10px;

	div {
		margin-top: 5px;
	}
}
