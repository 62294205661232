/* Слайдер */
.slick-slider{
	min-width: 0;
}
/* Ограничивающая оболочка */
.slick-list {
	overflow: hidden;
}
/* Лента слайдов */
.slick-track{
	display: flex;
	align-items: flex-start;
}

/* Слайд */
.slick-slide{}
/* Слайд активный */
.slick-slide.slick-active{}
/* Слайд основной */
.slick-slide.slick-current{}
/* Слайд по центру */
.slick-slide.slick-center{}

/* Стрелка */
.slick-arrow{}
/* Стрелка влево */
.slick-arrow.slick-prev{}
/* Стрелка вправо */
.slick-arrow.slick-next{}
/* Стрелка не активная */
.slick-arrow.slick-disabled{}

/* Точки */
.slick-dots{}
.slick-dots li{}
.slick-dots button{}

/* Конкретный слайдер */
.slider {
	position: relative;
	padding: 0px 60px;
}

@media (max-width:767px){
	.slider {
		padding: 0px 40px;
	}
}

.slider .slick-arrow{
	position: absolute;
	top: 50%;
	margin: -30px 0px 0px 0px;
	z-index: 10;
	font-size: 0;
	width: 30px;
	height: 60px;
}
.slider .slick-arrow.slick-prev{
	left: 0;
	background: url('./img/sl-arrow_l.svg') 0 0 / 100% no-repeat;
}
.slider .slick-arrow.slick-next{
	right: 0;
	background: url('./img/sl-arrow_r.svg') 0 0 / 100% no-repeat;
}
.slider .slick-arrow.slick-disabled{
	opacity: 0.3;
}

.slider .slick-dots{
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.slider .slick-dots li{
	list-style: none;
	margin: 0px 10px;
}
.slider .slick-dots button{
	font-size: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #00AEAE;
}

.slider .slick-dots li.slick-active button{
	background-color: transparent;
	background-color: #00AEAE;
}

.slider__item {
	padding: 0px 15px;
}
.slider__item img{
	max-width: 100%;
}

.slick-next:before, [dir=rtl] .slick-prev:before {
    content: '';
}
.slick-prev:before {
    content: '';
}

.slick-dots li button:before {
font-size: 0px;
}

.imgDetail {
	max-height: 700px;
	margin: auto;
	cursor: pointer;
}