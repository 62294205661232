.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(100, 100, 100);
}

.content {
    width: 70%;
}
