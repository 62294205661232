.wrapper {
	display: flex;
	justify-content: space-between;
	padding: 50px 20px;
	max-width: 1474px;
	width: 100%;
	margin: 30px auto;
}

.img {
	display: flex;
	flex-direction: column;
	max-width: 700px;
	width: 100%;
}

.content {
	display: flex;
	flex-direction: column;
	max-width: 500px;
	width: 100%;
	margin-left: 10px;
}

.skeletonItem {
	margin-top: 35px;
	height: 60px;
	max-width: 450px;
}

@media (max-width: 1200px) {
	.wrapper {
		flex-direction: column;
		align-items: center;
	}
}
