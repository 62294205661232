.wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 16px;
    /* width: 90%; */
}

.notFound {
    margin-top: 30px;
    font-size: 18px;
    color: #808080;
    text-align: center;
}

@media (max-width: 1200px) {
    .wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 95%;
    }
}

@media (max-width: 768px) {
    .wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media (max-width: 480px) {
    .wrapper {
        width: auto;
    }
}
