.wrapper {
	display: flex;
	flex-direction: column;
	padding: 98px 66px 42px;
	max-width: 1200px;
}

.content {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: minmax(100px, auto);
	grid-column-gap: 10px;
	width: 100%;
}

@media (max-width: 600px) {
	.content {
		grid-template-columns: repeat(1, 1fr);
	}
}
